export const paisesAutocomplete = [
  "CO",
  "CL",
  "EC",
  "US",
  "AR",
  "MX",
  "PE",
  "CR",
  "VZ",
  "GT",
];
