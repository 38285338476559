export const columnasRegistrosFormularios = [
  {
    label: "Id Registro Formulario Evento",
    field: "idRegistroFormularioEvento",
    sort: "asc",
    minimal: "lg",
    isVisible: true,
  },
  {
    label: "Id Formulario Evento",
    field: "idFormularioEvento",
    sort: "asc",
    minimal: "lg",
    isVisible: true,
  },
  {
    label: "Nombre Evento",
    field: "nombreEvento",
    sort: "asc",
    minimal: "lg",
    isVisible: true,
  },
  {
    label: "Nombre",
    field: "nombreCompleto",
    sort: "asc",
    minimal: "lg",
    isVisible: true,
  },
  {
    label: "Nombre Institución",
    field: "nombreInstitucion",
    sort: "asc",
    minimal: "lg",
    isVisible: true,
  },
  {
    label: "Correo",
    field: "correo",
    sort: "asc",
    minimal: "lg",
    isVisible: true,
  },
  {
    label: "Mayor de edad",
    field: "mayorEdad",
    sort: "asc",
    minimal: "lg",
    isVisible: true,
  },
  {
    label: "País",
    field: "pais",
    sort: "asc",
    minimal: "lg",
    isVisible: true,
  },
  {
    label: "Ciudad",
    field: "ciudad",
    sort: "asc",
    minimal: "lg",
    isVisible: true,
  },
  {
    label: "Pertenece a la Institución",
    field: "perteneceInstitucion",
    sort: "asc",
    minimal: "lg",
    isVisible: true,
  },
  {
    label: "Acceso Makemake",
    field: "accesoMakeMake",
    sort: "asc",
    minimal: "lg",
    isVisible: true,
  },
  {
    label: "Estado Inscripción",
    field: "estadoInscripcion",
    sort: "asc",
    minimal: "lg",
    isVisible: true,
  },
  {
    label: "Grupo",
    field: "grupo",
    sort: "asc",
    minimal: "lg",
    isVisible: true,
  },
];
